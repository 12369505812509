import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import { ReactReader, ReactReaderStyle } from 'react-reader'
import { setLocalStorage, getLocalStorage } from '../utils'
import ShareBlock from '../components/ShareBlock'
import AlsoRead from '../components/articles/AlsoRead'

export const BooksPageTemplate = ({ publication, pageLocation }) => {
  const savedLocation = getLocalStorage(`book.${publication.id}`) || null
  const [location, setLocation] = useState(savedLocation)
  const updateLastLocation = (loc) => {
    setLocalStorage(`book.${publication.id}`, loc)
    setLocation(loc)
  }

  return (
    <div className="container">
      <div
        style={{
          position: 'relative',
          direction: 'rtl',
          height: '100vh',
          width: '100%',
        }}
      >
        {' '}
        <ReactReader
          url={publication.ebook}
          title={publication.title}
          location={location}
          showToc
          styles={{
            ...ReactReaderStyle,
            tocButtonBar: {
              ...ReactReaderStyle.tocButtonBar,
              background: '#9b4809',
            },
            prev: { right: 1 },
            next: { left: 1 },
          }}
          locationChanged={(loc) => updateLastLocation(loc)}
          swipeable
        />
      </div>
      <div className="container">
        <ShareBlock
          blockTitle="شارك الكتاب"
          containerStyle="has-text-centered has-text-link"
          url={pageLocation.href}
          slug={publication.title}
          text={`اقرا كتاب "${publication.title} | ${publication.author}"  كاملا على موقع مركز الدراسات السودانية | @SudanSSC `}
        />

        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLScyqDz2sQgFwEXL3cOl8AhYsvGAjCUypAsMzCerCcbiGSNMTg/viewform?embedded=true&hl=ar"
          width="100%"
          height="594"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="loading"
        >
          Loading…
        </iframe>
      </div>
      <section className="section">
        <div className="container">
          <div className="content">
            <AlsoRead />
          </div>
        </div>
      </section>
    </div>
  )
}

BooksPageTemplate.propTypes = {
  publication: PropTypes.shape({
    id: PropTypes.string,
    ebook: PropTypes.string,
    title: PropTypes.string,
    author: PropTypes.string,
  }),
  pageLocation: PropTypes.shape({
    href: PropTypes.string,
  }),
}

const BooksPage = ({ pageContext: { id, ebook, title, author }, location }) => {
  const publication = { id, ebook, title, author }

  return (
    <Layout>
      <BooksPageTemplate pageLocation={location} publication={publication} />
    </Layout>
  )
}

BooksPage.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string,
    ebook: PropTypes.string,
    title: PropTypes.string,
    author: PropTypes.string,
  }),
  location: PropTypes.object,
}

export default BooksPage
